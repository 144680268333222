import { useRollbarPerson } from '@rollbar/react';
import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ErrorBoundary } from '../../components/ErrorHandling';
import { useAuth } from '../../context/AuthContext';
import { FeaturesProvider } from '../../context/FeaturesContext';
import { Redirect } from '../../Router';
import { root as authRoot } from '../auth/routes';
import CurrentTimeTrackingWidget from './components/CurrentTimeTrackingWidget';
import RedirectToHome from './components/RedirectToHome';
import { pageView } from './context/Analytics';
import { Provider as ApolloProvider } from './context/Apollo';
import { UserInfoProvider, useUserInfo } from './context/UserInfo';
import {
  useProcessSocketStateChanged,
  useProcessWebSocketMessages,
} from './hooks/useProcessWebsocketMessage';
import { WebSocketProvider } from './hooks/webSocket';
import { routes } from './routes';
import { HubContainer } from '../../components/HubContainer/HubContainer';
import { LoomContextProvider } from './components/LoomVideo/LoomContext';
import { TimeTrackingMutationsContextProvider } from './hooks/timeTracking';
import { MainMenuBar } from './components/MainMenuBar/MainMenuBar';
import TopNav from './components/TopNav';
import { FlairBreadcrumbProvider } from '../../context/FlairBreadcrumbContext';
import { MenuBarProvider } from './context/MenuBar';
import { AutoLogout } from './components/AutoLogout';

const Content: React.FC = () => {
  const userInfo = useUserInfo();

  const processMessage = useProcessWebSocketMessages();
  const processSocketStateChanged = useProcessSocketStateChanged();

  return (
    <WebSocketProvider
      messageReceived={processMessage}
      socketStateChanged={processSocketStateChanged}>
      <ErrorBoundary>
        <Switch>
          {Object.values(routes).map(({ render, route }, i) => (
            <Route
              key={i}
              exact={route.template() === '/'}
              path={route.template()}>
              {render(userInfo)}
            </Route>
          ))}
          <Route path="*">
            <RedirectToHome />
          </Route>
        </Switch>
      </ErrorBoundary>
    </WebSocketProvider>
  );
};

const TrackPageViews: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    pageView();
  }, [location]);

  return <></>;
};

const RollbarPesonTracking: React.FC = () => {
  const { id } = useUserInfo();
  useRollbarPerson({ id });
  return <></>;
};

const RedirectToLogin: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <Redirect
      to={
        pathname === '/'
          ? authRoot.route
          : authRoot.route.withQueryParams({
              redirectTo: pathname,
            })
      }
    />
  );
};

const App: React.FC = () => {
  const isLoggedIn = useAuth().isLoggedIn;

  if (!isLoggedIn) {
    return <RedirectToLogin />;
  }

  return (
    <ApolloProvider>
      <UserInfoProvider>
        <FeaturesProvider>
          <LoomContextProvider>
            <ErrorBoundary>
              <FlairBreadcrumbProvider>
                <TimeTrackingMutationsContextProvider>
                  <TrackPageViews />
                  <RollbarPesonTracking />
                  <MenuBarProvider>
                    <TopNav />
                    <MainMenuBar />
                  </MenuBarProvider>
                  <div className="main-content">
                    <HubContainer>
                      <Content />
                    </HubContainer>
                  </div>
                  <CurrentTimeTrackingWidget />
                  <AutoLogout />
                </TimeTrackingMutationsContextProvider>
              </FlairBreadcrumbProvider>
            </ErrorBoundary>
          </LoomContextProvider>
        </FeaturesProvider>
      </UserInfoProvider>
    </ApolloProvider>
  );
};

export default App;
