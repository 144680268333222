import { param, route } from '../../utils/router';
import ChangeEmail from './pages/ChangeEmail';
import ForgotPassword from './pages/ForgotPassword';
import Login from './pages/Login';
import Logout from './pages/Logout';
import { OrganizationInfoPublicPage } from './pages/OrganizationInfoPublicPage';
import { RedirectToStorybook } from './pages/RedirectToStorybook';
import Register from './pages/Register';
import ResetPassword from './pages/ResetPassword';
import SessionExpired from './pages/SessionExpired';
import SessionTimeout from './pages/SessionTimeout';

export const routes = {
  forgotPassword: {
    route: route('login', 'forgot-password'),
    component: ForgotPassword,
  },
  login: {
    route: route('login'),
    component: Login,
  },
  logout: {
    route: route('logout'),
    component: Logout,
  },
  register: {
    route: route(param('organizationId'), 'register'),
    component: Register,
  },
  changeEmail: {
    route: route(param('organizationId'), 'change_email'),
    component: ChangeEmail,
  },
  resetPassword: {
    route: route(param('organizationId'), 'reset_password'),
    component: ResetPassword,
  },
  organizationInfo: {
    route: route(param('organizationId'), 'info'),
    component: OrganizationInfoPublicPage,
  },
  sessionTimeout: {
    route: route('session-timeout'),
    component: SessionTimeout,
  },
  sessionExpired: {
    route: route('session-expired'),
    component: SessionExpired,
  },
  storybook: {
    route: route('storybook'),
    component: RedirectToStorybook,
  },
};

export const root = routes.login;

export const pathTemplates: string[] = Object.values(routes).map(({ route }) =>
  route.template(),
);
