import { isHttpErrorCode, isLoginResultSuccess, LoginResult } from './types';

export const loginByEmail = async (
  email: string,
  password: string,
): Promise<LoginResult> => {
  const response = await fetch(getAuthApiUrl() + '/auth/login/email', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password }),
  });
  const responseData = await response.json();

  if (response.ok) {
    if (isLoginResultSuccess(responseData)) {
      return responseData;
    } else {
      throw new Error('Invalid response', responseData);
    }
  }
  if (isHttpErrorCode(responseData)) {
    return responseData;
  }
  throw new Error('Unknown error', responseData);
};

export const refreshAccessToken = (): Promise<string> => {
  return fetch(getAuthApiUrl() + '/auth/refresh', {
    method: 'POST',
    credentials: 'include',
  })
    .then(async (response) => {
      if (await isRefreshTokenExpired(response.clone())) {
        window.location.href = '/session-expired';
        return;
      }
      if (!response.ok) {
        const bodyText = await response.text();
        throw new Error('Failed to refresh access token: ' + bodyText);
      }
      return response.json();
    })
    .then((data) => data.accessToken);
};

const getAuthApiUrl = () =>
  import.meta.env.VITE_APP_AUTH_API_URL
    ? import.meta.env.VITE_APP_AUTH_API_URL + '/api'
    : getApiBaseUrl();

const getApiBaseUrl = () => import.meta.env.VITE_APP_API_BASE_URL ?? '/api';

const isRefreshTokenExpired = async (response: Response): Promise<boolean> => {
  try {
    const errorResponse = await response.json();
    const errorCode = errorResponse?.error?.code;
    if (errorCode === 'INVALID_DATA' || errorCode === 'REFRESH_TOKEN_EXPIRED') {
      return true;
    }
  } catch (e) {
    return false;
  }
  return false;
};
