import React, { useEffect } from 'react';
import { ensureJwtAccessTokenRefreshed } from '../api/jwtAuth';
import { sendAccessTokenToSw } from '../utils/serviceWorkerUtils';
import { addDays } from 'date-fns';

type Auth = {
  isLoggedIn: boolean;
  login: (apiToken: string) => void;
  logout: () => void;
};

const AuthenticatedContext = React.createContext<Auth>({
  isLoggedIn: false,
  login: () => {},
  logout: () => {},
});

const API_TOKEN_STORAGE_KEY = 'flairEmployeeHubApiToken';
const API_DOCUMENTS_TOKEN_COOKIE = 'flairDocumentsToken';

export const ensureApiTokenRefreshed = async (): Promise<string | null> => {
  const apiToken = getApiToken();
  if (!apiToken) {
    return null;
  }
  const isJwtToken = apiToken?.startsWith('Bearer');
  if (!isJwtToken) {
    return apiToken;
  }
  const jwtToken = apiToken?.split(' ')[1];
  const refreshResult = await ensureJwtAccessTokenRefreshed(jwtToken);
  if (refreshResult.isRefreshed) {
    saveApiToken(`Bearer ${refreshResult.jwtAccessToken}`);
  }
  return `Bearer ${refreshResult.jwtAccessToken}`;
};

export const getApiToken = (): string | null => {
  return localStorage.getItem(API_TOKEN_STORAGE_KEY);
};

export const saveApiToken = (apiToken: string | null) => {
  if (apiToken) {
    localStorage.setItem(API_TOKEN_STORAGE_KEY, apiToken);
    createTempFallbackCookie(apiToken);
  } else {
    localStorage.removeItem(API_TOKEN_STORAGE_KEY);
    deleteTempFallbackCookie();
  }
  sendAccessTokenToSw(apiToken);
};

export const AuthProvider: React.FC = (props) => {
  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>(
    getApiToken() !== null,
  );

  useEffect(() => {
    const apiToken = getApiToken();
    // to make service worker aware of the access token after app restart
    if (isLoggedIn && apiToken !== null) {
      sendAccessTokenToSw(apiToken);
      createTempFallbackCookie(apiToken);
    }
  }, [isLoggedIn]);

  const logout = () => {
    saveApiToken(null);
    setIsLoggedIn(false);
  };

  const login = (apiToken: string) => {
    saveApiToken(apiToken);
    setIsLoggedIn(true);
  };

  return (
    <AuthenticatedContext.Provider
      value={{ isLoggedIn, login, logout }}
      {...props}
    />
  );
};

export const useAuth = (): Auth => React.useContext(AuthenticatedContext);

// todo: That is workaround
// We should really store access token in the cookie (set it from server)
// https://linear.app/flair/issue/CHR-901/store-access-token-in-cookie
const deleteTempFallbackCookie = () => {
  document.cookie = `${API_DOCUMENTS_TOKEN_COOKIE}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/api/documents/`;
};

const createTempFallbackCookie = (apiToken: string) => {
  const expire = addDays(new Date(), 100);
  const encodedToken = encodeURIComponent(apiToken);
  document.cookie = `${API_DOCUMENTS_TOKEN_COOKIE}=${encodedToken};expires=${expire.toUTCString()};secure;path=/api/documents/`;
};
