import React from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Form } from '../../../../components/form/Form';
import { Input } from '../../../../components/form/Input';
import InputLabel from '../../../../components/form/InputLabel';
import SubmitButton from '../../../../components/form/SubmitButton';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Link } from '../../../../Router';
import { routes } from '../../routes';

export type LoginHistoryLocationState = {
  alertMessage?: 'reset-password' | 'forgot-password' | 'change-email';
};

export const AlertMessage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory<LoginHistoryLocationState>();

  switch (history.location.state?.alertMessage) {
    case 'reset-password': {
      return <Alert variant="info">{t('signIn.info.passwordReset')}</Alert>;
    }

    case 'forgot-password': {
      return <Alert variant="info">{t('signIn.info.forgotPassword')}</Alert>;
    }

    case 'change-email': {
      return <Alert variant="info">{t('signIn.info.changeEmail')}</Alert>;
    }

    default: {
      return <></>;
    }
  }
};

export type LoginFormData = {
  email: string;
  password: string;
};

const defaultValues: LoginFormData = {
  email: '',
  password: '',
};

const formSchema = yup.object().shape<LoginFormData>({
  email: yup.string().trim().required().email(),
  password: yup.string().required().min(8),
});

type LoginPasswordFormCommonProps = {
  onSubmit: (data: LoginFormData) => void;
  loginErrors: boolean;
};

const LoginPasswordFormCommon: React.FC<LoginPasswordFormCommonProps> = ({
  onSubmit,
  loginErrors,
}) => {
  const { t } = useTranslation();

  return (
    <Form
      onSubmit={onSubmit}
      validationSchema={formSchema}
      defaultValues={defaultValues}>
      <Input
        label={t('form.general.email.label')}
        placeholder={t('form.general.email.placeholder')}
        name="email"
        autoFocus
      />

      <Input
        placeholder={t('form.general.password.placeholder')}
        name="password"
        type="password"
        labelComponent={
          <Row>
            <Col>
              <InputLabel label={t('form.general.password.label')} />
            </Col>
            <Col className="col-auto">
              <Link
                to={routes.forgotPassword.route}
                className="form-text small text-muted"
                tabIndex={-1}>
                {t('signIn.forgotPasswordLink')}
              </Link>
            </Col>
          </Row>
        }
      />

      {loginErrors ? (
        <p className="text-danger">{t('signIn.invalid')}</p>
      ) : (
        <></>
      )}

      <SubmitButton block>{t('signIn.button')}</SubmitButton>
    </Form>
  );
};

export default LoginPasswordFormCommon;
