import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  SDKUnsupportedReasons,
  isSupported as isLoomSupported,
  setup as setupLoom,
  ConfigureButton,
} from '@loomhq/record-sdk';

import { ensureApiTokenRefreshed } from '../../../../context/AuthContext';
import { getLoomJwtToken, getLoomJwtTokenInfo } from './loomJwtToken';

type LoomStateSupported = {
  supported: true;
  publicAppId: string;
  configureButton: ConfigureButton;
};

type LoomStateUnsupported = {
  supported: false;
  unsupportReason: SDKUnsupportedReasons;
};

type LoomContextValue = {
  loomState: LoomStateSupported | LoomStateUnsupported | undefined;
  setup: () => Promise<void>;
};

const LoomContext = React.createContext<LoomContextValue>({
  loomState: undefined,
  setup: () => {
    return Promise.resolve();
  },
});

export const LoomContextProvider: React.FC = (props) => {
  const [loomState, setLoomState] = useState<
    LoomStateSupported | LoomStateUnsupported | undefined
  >(undefined);
  const isSetupCalled = useRef(false);

  const setup = useCallback(async () => {
    if (isSetupCalled.current) {
      return;
    }
    isSetupCalled.current = true;
    const supportInfo = await isLoomSupported();
    if (!supportInfo.supported) {
      setLoomState({
        supported: false,
        unsupportReason: supportInfo.error ?? 'incompatible-browser',
      });
      return;
    }

    const apiToken = await ensureApiTokenRefreshed();
    if (!apiToken) {
      return;
    }
    const jwtToken = await getLoomJwtToken({ apiToken });
    const tokenInfo = getLoomJwtTokenInfo(jwtToken);
    if (!tokenInfo) {
      return;
    }
    const { configureButton } = await setupLoom({
      jws: jwtToken,
      config: {
        defaultRecordingType: 'cam',
      },
    });
    setLoomState({
      supported: true,
      publicAppId: tokenInfo.loomAppId,
      configureButton,
    });
    return;
  }, [setLoomState]);

  return <LoomContext.Provider value={{ loomState, setup }} {...props} />;
};

export const useLoomContext = () => {
  const context = React.useContext(LoomContext);
  // initialize on first call
  useEffect(() => {
    if (context.loomState === undefined) {
      context.setup();
    }
  }, [context]);
  return context;
};
