import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutationErrorHandler } from '../../../../hooks/useMutationErrorHandler';
import { useJwtAuth } from './useJwtLogin';
import LoginPasswordFormCommon, {
  AlertMessage,
  LoginFormData,
} from './LoginPasswordFormCommon';

const LoginPasswordForm: React.FC = () => {
  const { doLogin, loginErrors } = useJwtAuth();
  const errorHandler = useMutationErrorHandler();
  const { t } = useTranslation();

  const onSubmit = async (values: LoginFormData) => {
    await doLogin(values['email'], values['password']).catch(errorHandler);
  };

  return (
    <>
      <h1 className="display-4 text-center mb-3">
        {t('signIn.title')} <small>(jwt beta)</small>
      </h1>
      <p className="text-muted text-center mb-5">{t('signIn.subTitle')}</p>
      <AlertMessage />
      <LoginPasswordFormCommon loginErrors={loginErrors} onSubmit={onSubmit} />
    </>
  );
};

export default LoginPasswordForm;
