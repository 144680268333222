import React, { useMemo } from 'react';
import { Column, Row } from 'react-table';
import { Trans, useTranslation } from 'react-i18next';
import { TableContent } from '../../../manager/components/TableContent';
import { EmployeeWithAvatar } from '../../../components/Employee';
import FormattedDate from '../../../../../components/date/FormattedDate';
import { parseDate } from '../../../../../utils/dateUtils';
import CommentsPopover from '../../../components/Comment/CommentsPopover';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import {
  DocumentEmployee,
  DocumentFilesType,
  DocumentStatus,
  File,
  Navigatable,
} from '../types';
import { EmptyDocuments } from '../EmptyDocuments';
import { RelatedObjectNames } from '../../../components/Comment/types';
import { DropdownActionItem } from '../../../../../atomic/molecules/DropdownActionItem';
import { DocumentCell } from './DocumentCell';
import { DocumentItemStatus } from './DocumentItemStatus';
import {
  NavigatableIdInput,
  NavigatableType,
} from '../../../__generated__/graphql';
import { FilteredEmptyDocuments } from '../FilteredEmptyDocuments';
import { IndeterminateCheckbox } from '../../../components/Checkbox/IndeterminateCheckbox';
import { useTableSort } from '../../../../../context/TableSortingContext';
import { DropdownActions } from '../../../../../atomic/templates/DropdownActions';

export type TableItem = {
  id: string;
  name: string;
  note: string | null;
  category: string;
  categoryId: string | null;
  type: DocumentFilesType;
  employee: DocumentEmployee;
  addedOn: string;
  status: DocumentStatus | null;
  typeName: NavigatableType;
  recordId: string | null;
  fullPath: string;
  file?: File;
  subRows?: TableItem[];
  commentsCount: number;
  canDelete: boolean;
};

const i18Prefix = 'documents2.personal';

type Props = {
  documents: Navigatable[];
  onDelete: (item: NavigatableIdInput, fileType: string) => void;
  hasPersonalDocumentFilter: boolean;
  onClick: (file: TableItem) => void;
  onMove: (item: NavigatableIdInput) => void;
  onDownload: (url?: string) => void;
  resetSelectedRows: boolean;
  selectAllRows: boolean;
  setSelectedRows: (rows?: TableItem[]) => void;
  isManager: boolean;
  onRename: (item: NavigatableIdInput & { name: string }) => void;
};

export const PersonalDocumentsTable: React.FC<Props> = ({
  documents,
  onDelete,
  onMove,
  onClick,
  onDownload,
  onRename,
  hasPersonalDocumentFilter,
  setSelectedRows,
  resetSelectedRows,
  selectAllRows,
  isManager,
}) => {
  const { t } = useTranslation();
  const items = useMemo(() => documents.map(mapToTableItem), [documents]);
  const { sortBy, onSortChange } = useTableSort();
  const columns: Column<TableItem>[] = useMemo(() => {
    const columnsWithNull: (Column<TableItem> | null)[] = [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
        ),
        Cell: ({ row }: { value: string; row: Row<TableItem> }) => {
          if (row.depth > 0) return <div />;
          return <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />;
        },
        disableSortBy: true,
      },
      {
        Header: () => (
          <Trans t={t} i18nKey={`${t(`${i18Prefix}.table.header.name`)}`} />
        ),
        accessor: 'name',
        id: 'expander',
        Cell: ({ value, row }: { value: string; row: Row<TableItem> }) => {
          return (
            <div
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth}rem`,
                },
              })}>
              <DocumentCell
                name={value}
                hasSubRow={!!row.original.subRows?.length || false}
                isExpanded={row.isExpanded}
                onClick={() => {
                  onClick(row.original);
                }}
                fileType={row.original.type.type}></DocumentCell>
            </div>
          );
        },
      },
      {
        Header: () => (
          <Trans t={t} i18nKey={`${t(`${i18Prefix}.table.header.category`)}`} />
        ),
        accessor: 'category',
        Cell: ({ value }: { value: string }) => <div>{value}</div>,
      },
      {
        Header: () => (
          <Trans t={t} i18nKey={`${t(`${i18Prefix}.table.header.addedBy`)}`} />
        ),
        accessor: 'employee',
        Cell: ({ value }: { value: DocumentEmployee }) => {
          return (
            <EmployeeWithAvatar
              employee={{
                name: value?.name,
                avatarUrl: value?.avatarUrl,
              }}></EmployeeWithAvatar>
          );
        },
      },
      {
        Header: () => (
          <Trans t={t} i18nKey={`${t(`${i18Prefix}.table.header.addedOn`)}`} />
        ),
        accessor: 'addedOn',
        Cell: ({ value }: { value: string | null }) =>
          value ? (
            <FormattedDate day={parseDate(value)} format="short" />
          ) : (
            <span>-</span>
          ),
      },
      {
        Header: () => (
          <Trans t={t} i18nKey={`${t(`${i18Prefix}.table.header.comments`)}`} />
        ),
        accessor: 'recordId',
        Cell: ({ value, row }) =>
          row.depth > 0 || !value ? (
            <span>-</span>
          ) : (
            <div className="mt-2">
              <CommentsPopover
                recordId={value}
                commentsCount={row.original.commentsCount}
                relatedObjectName={RelatedObjectNames.Document}
                mode="icon"
              />
            </div>
          ),
      },
      {
        Header: () => (
          <Trans t={t} i18nKey={`${t(`${i18Prefix}.table.header.status`)}`} />
        ),
        accessor: 'status',
        Cell: ({ value }: { value: DocumentStatus }) => {
          return <DocumentItemStatus status={value} />;
        },
      },
      {
        id: 'actions',
        Cell: ({ row }: { value: string; row: Row<TableItem> }) => (
          <DropdownActions
            className="d-flex justify-content-end"
            id="personal-documents"
            icon={<FlairIcon icon="three-dots" />}>
            <DropdownActionItem
              disabled={row.depth > 0}
              onClick={() => {
                onRename({
                  id: row.original.id,
                  type: row.original.typeName,
                  name: row.original.name,
                });
              }}>
              {t(`${i18Prefix}.table.actions.rename`)}
            </DropdownActionItem>
            <DropdownActionItem
              disabled={
                !!row.original.subRows?.length ||
                row.original.typeName === NavigatableType.EmployeeDocumentFolder
              }
              onClick={() => {
                onDownload(row.original.file?.publicLink?.downloadLink);
              }}>
              {t(`${i18Prefix}.table.actions.download`)}
            </DropdownActionItem>
            <DropdownActionItem
              onClick={() => {
                onMove({ id: row.original.id, type: row.original.typeName });
              }}>
              {t(`${i18Prefix}.table.actions.move`)}
            </DropdownActionItem>
            {!isManager && (
              <DropdownActionItem
                disabled={!row.original.canDelete}
                onClick={() => {
                  onDelete(
                    { id: row.original.id, type: row.original.typeName },
                    row.original.type.type,
                  );
                }}>
                {t(`${i18Prefix}.table.actions.delete`)}
              </DropdownActionItem>
            )}
          </DropdownActions>
        ),
      },
    ];
    return columnsWithNull.filter((x): x is Column<TableItem> => x !== null);
  }, [isManager, onClick, onDelete, onDownload, onMove, onRename, t]);

  if (!items.length && !hasPersonalDocumentFilter) {
    return <EmptyDocuments />;
  }
  if (!items.length && hasPersonalDocumentFilter) {
    return <FilteredEmptyDocuments />;
  }

  return (
    <TableContent
      selectAllRows={selectAllRows}
      resetSelectedRows={resetSelectedRows}
      onRowSelection={setSelectedRows}
      columns={columns}
      initialSortBy={sortBy}
      data={items}
      onSortChange={onSortChange}
      autoResetSortBy={true}
      tableOptions={{
        autoResetExpanded: false,
      }}
    />
  );
};

function mapToTableItem(src: Navigatable): TableItem {
  let tableItem: TableItem = {
    id: src.id,
    name: src.name,
    category: src.category,
    employee: src.employee,
    categoryId: src.categoryId,
    addedOn: src.addedOn,
    status: src.status,
    type: src.type,
    fullPath: src.fullPath,
    typeName: src.typeName,
    note: src.note,
    recordId: src.recordId,
    commentsCount: src.commentsCount,
    canDelete: src.canDelete,
  };

  if (src.files) {
    // We will show the file directly
    if (src.files.length === 1) {
      tableItem.file = src.files[0];
    }

    //we will show the files as sub-rows
    if (src.files.length > 1) {
      tableItem.subRows = src.files.map((file) => mapToTableSubItem(src, file));
    }
  }

  return tableItem;
}

function mapToTableSubItem(src: Navigatable, file: File): TableItem {
  return {
    id: file.id,
    name: file.title,
    category: src.category,
    categoryId: src.categoryId,
    employee: src.employee,
    addedOn: src.addedOn,
    typeName: src.typeName,
    type: { filesCount: 1, type: file.type },
    status: src.status,
    fullPath: src.fullPath,
    note: src.note,
    file: file,
    recordId: src.recordId,
    commentsCount: src.commentsCount,
    canDelete: src.canDelete,
  };
}
