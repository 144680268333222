// from packages/web/src/api/jwtAuth/index.ts
export type HttpErrorCode =
  | 'INVALID_CREDENTIALS'
  | 'INVALID_DATA'
  | 'UNKNOWN_ERROR'
  | 'REFRESH_TOKEN_EXPIRED';

export const isHttpErrorCode = (code: unknown): code is HttpErrorCode => {
  return (
    typeof code === 'string' &&
    [
      'INVALID_CREDENTIALS',
      'INVALID_DATA',
      'UNKNOWN_ERROR',
      'REFRESH_TOKEN_EXPIRED',
    ].includes(code)
  );
};

export type LoginResultSuccess = { accessToken: string; refreshToken: string };
export const isLoginResultSuccess = (
  result: unknown,
): result is LoginResultSuccess => {
  return (
    typeof result === 'object' &&
    result !== null &&
    'accessToken' in result &&
    typeof result.accessToken === 'string' &&
    result.accessToken?.length > 0
  );
};

export type LoginResult = LoginResultSuccess | HttpErrorCode;
