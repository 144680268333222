import React from 'react';
import * as yup from 'yup';
import { Form } from '../../../../components/form/Form';
import { Input } from '../../../../components/form/Input';
import SubmitButton from '../../../../components/form/SubmitButton';
import { useTranslation } from 'react-i18next';
import { useForgotPasswordMutation } from '../../__generated__/graphql';
import { useHistory } from 'react-router-dom';
import { routes } from '../../routes';
import { useMutationErrorHandler } from '../../../../hooks/useMutationErrorHandler';
import { LoginHistoryLocationState } from '../Login/LoginPasswordFormCommon';

type FormData = {
  email: string;
};

const defaultValues: FormData = {
  email: '',
};

const formSchema = yup.object().shape<FormData>({
  email: yup.string().required().email(),
});

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory<LoginHistoryLocationState>();
  const [forgotPassword] = useForgotPasswordMutation();
  const errorHandler = useMutationErrorHandler();
  const onSubmit = async ({ email }: FormData) => {
    await forgotPassword({ variables: { input: { email } } })
      .then(() =>
        history.push(routes.login.route.create({}), {
          alertMessage: 'forgot-password',
        }),
      )
      .catch(errorHandler);
  };

  return (
    <>
      <h1 className="display-4 text-center mb-3">
        {t('forgotPassword.title')}
      </h1>
      <p className="text-muted text-center mb-5">
        {t('forgotPassword.subTitle')}
      </p>
      <Form
        onSubmit={onSubmit}
        validationSchema={formSchema}
        defaultValues={defaultValues}>
        <Input
          label={t('form.general.email.label')}
          placeholder={t('form.general.email.placeholder')}
          name="email"
          autoFocus
        />
        <SubmitButton block>{t('forgotPassword.button')}</SubmitButton>
      </Form>
    </>
  );
};

export default ForgotPassword;
