import { jwtDecode } from 'jwt-decode';
import { refreshAccessToken } from './jwtAuthApi';

let isRefreshing = false;
let refreshPromise: Promise<string> | null = null;

export const API_TOKEN_STORAGE_KEY = 'flairEmployeeHubApiToken';
const REFRESH_BEFORE_EXPIRE_INTERVAL_SECONDS = 60;

type RefreshAccessTokenResult = {
  isRefreshed: boolean;
  jwtAccessToken: string;
};

export const ensureJwtAccessTokenRefreshed = async (
  jwtAccessToken: string,
): Promise<RefreshAccessTokenResult> => {
  const { exp } = jwtDecode(jwtAccessToken);
  if (
    exp &&
    Date.now() + REFRESH_BEFORE_EXPIRE_INTERVAL_SECONDS * 1000 >= exp * 1000
  ) {
    return refreshAccessTokenSinglePromise().then((jwtAccessToken) => ({
      isRefreshed: true,
      jwtAccessToken,
    }));
  } else {
    return { isRefreshed: false, jwtAccessToken };
  }
};

export const refreshAccessTokenSinglePromise = async (): Promise<string> => {
  if (!isRefreshing) {
    isRefreshing = true;
    refreshPromise = refreshAccessToken().finally(() => {
      isRefreshing = false;
      refreshPromise = null;
    });
  }
  return refreshPromise!;
};
