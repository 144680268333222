import i18next from 'i18next';
import { SupportedLocal } from 'web/src/apps/dashboard/__generated__/graphql';

export type Language =
  | SupportedLocal.En
  | SupportedLocal.De
  | SupportedLocal.Fr
  | SupportedLocal.Es;

export const getCurrentLanguage = (): Language => {
  const lang = i18next.language.toLowerCase().replace('-', '_');
  return getLanguage(lang);
};

export const getLanguage = (lang: string): Language => {
  if (
    lang === SupportedLocal.En ||
    lang === SupportedLocal.De ||
    lang === SupportedLocal.Fr ||
    lang === SupportedLocal.Es
  ) {
    return lang;
  } else if (lang === SupportedLocal.DeCh) {
    return SupportedLocal.De;
  }
  return SupportedLocal.En;
};
