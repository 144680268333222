import { MutationResult } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from '../../../../context/Toast';
import { useMutationErrorHandler } from '../../../../hooks/useMutationErrorHandler';
import {
  StopMyTimeEntryMutation,
  TimeEntryBreakInput,
  useStopMyTimeEntryMutation,
} from '../../__generated__/graphql';
import { useTimeTrackingErrorsAndWarnings } from './useTimeTrackingErrorsAndWarnings';

type Result = [
  (
    timeEntryId: string,
    notes?: string,
    updateBreaks?: TimeEntryBreakInput[],
  ) => Promise<void>,
  MutationResult<StopMyTimeEntryMutation>,
];

type Args = {
  onSuccess: () => void;
};

export const useStopTimeTracking = ({ onSuccess }: Args): Result => {
  const { t } = useTranslation();
  const [stopTimeEntry, mutationResult] = useStopMyTimeEntryMutation();
  const { addSuccess } = useToasts();
  const errorHandler = useMutationErrorHandler();
  const { showErrorsAndWarningsToasts } = useTimeTrackingErrorsAndWarnings();

  const handleOnStop = useCallback(
    (
      timeEntryId: string,
      notes?: string,
      updateBreaks?: TimeEntryBreakInput[],
    ) =>
      stopTimeEntry({
        variables: {
          input: {
            Id: timeEntryId,
            notes: notes ?? null,
            updateBreaks: updateBreaks ?? null,
            geolocation: null,
          },
        },
      })
        .then((response) => {
          const errors = response.data?.timeTracking.stopMyTimeEntry.errors2;
          showErrorsAndWarningsToasts({ errors });
          const autodeducted =
            response.data?.timeTracking.stopMyTimeEntry.record?.breaks.some(
              (x) => x.flair__Auto_adjusted_Datetime__c !== null,
            ) ?? false;
          if (autodeducted) {
            addSuccess(
              t('timeTracking.current.steps.working.clockOut.autodeducted'),
            );
          }
          onSuccess();
        })
        .catch(errorHandler),
    [
      stopTimeEntry,
      onSuccess,
      errorHandler,
      showErrorsAndWarningsToasts,
      addSuccess,
      t,
    ],
  );

  return [handleOnStop, mutationResult];
};
