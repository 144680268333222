import {
  ContentDocumentLinksDataFragment,
  CorporateDocumentDataFragment,
  CorporateDocumentsQuery,
  DocumentCreatedByColleagueFragment,
  NavigatableFragment,
  NavigatableType,
  PersonalDocumentFieldsFragment,
  PersonalDocumentFolderDataFragment,
  PersonalDocumentsQuery,
} from '../../__generated__/graphql';
import {
  Breadcrumb,
  CorporateDocument,
  DocumentEmployee,
  DocumentFilesType,
  DocumentPreview,
  DocumentStatus,
  File,
  Navigatable,
} from './types';
import { RowFileTypeEnums } from './Enums/RowFileTypeEnums';
import { Theme } from '../../../../theme';
import _ from 'lodash';
import i18next from 'i18next';
import { DOCUMENT_OWNERS } from './constants';

export const ROOT_FOLDER = 'Home';
export const mapCorporateDocuments = (
  src: CorporateDocumentsQuery,
): CorporateDocument[] => {
  return src.companyDocuments.map((x) => mapCorporateDocument(x));
};

export const mapPersonalDocuments = (
  src: PersonalDocumentsQuery,
  currentEmployeeId: string,
): Navigatable[] => {
  if (!src.navigatable) {
    return [];
  }
  const navigatables = src.navigatable.children.map((item) =>
    mapNavigatable(item, currentEmployeeId),
  );
  return _.orderBy(navigatables, 'name', 'desc');
};

const mapNavigatable = (
  src: NavigatableFragment,
  currentEmployeeId: string,
): Navigatable => {
  switch (src.__typename) {
    case 'EmployeeDocument':
      return mapPersonalDocument(src, currentEmployeeId);
    case 'EmployeeDocumentFolder':
      return mapPersonalDocumentFolder(src, currentEmployeeId);
    default:
      throw new Error('Not supported');
  }
};

const mapPersonalDocument = (
  src: PersonalDocumentFieldsFragment,
  currentEmployeeId: string,
): Navigatable => {
  return {
    id: src.Id,
    name: src.Name,
    note: src.flair__Note__c,
    addedOn: src.CreatedDate,
    employee: src.createdBy
      ? mapCreatedByEmployee(src.createdBy, currentEmployeeId)
      : { id: DOCUMENT_OWNERS.HR, name: DOCUMENT_OWNERS.HR },
    files: src.ContentDocumentLinks__r.map((x) => mapContentDocumentLinks(x)),
    canDelete: src.canDelete,
    type: getRowFileType(
      src.ContentDocumentLinks__r as ContentDocumentLinksDataFragment[],
    ),
    category: src.documentCategory.Name,
    categoryId: src.documentCategory.Id,
    typeName: NavigatableType.EmployeeDocument,
    status: src.flair__Approval_Status__c as DocumentStatus,
    recordId: src.Id,
    fullPath: src.fullPath,
    commentsCount: src.commentsCount,
  };
};

const getRowFileType = (
  files?: ContentDocumentLinksDataFragment[],
): DocumentFilesType => {
  if (files && files?.length > 1) {
    return { filesCount: files?.length, type: RowFileTypeEnums.Multi };
  } else if (files?.length === 1) {
    return { filesCount: 1, type: files[0].ContentDocument.FileType };
  }
  //TODO: after folder functionality is implemented change the condition;
  return { filesCount: 0, type: RowFileTypeEnums.EmptyEmployeeDocument };
};

const mapContentDocumentLinks = (
  src: ContentDocumentLinksDataFragment,
): File => {
  return {
    id: src.Id,
    documentId: src.LinkedEntityId,
    type: src.ContentDocument.FileType,
    extension: src.ContentDocument.FileExtension,
    title: src.ContentDocument.Title,
    typeName: NavigatableType.EmployeeDocument,
    size: src.ContentDocument.ContentSize,
    publicLink: {
      downloadLink: src.downloadUrl,
      viewLink: src.viewUrl,
    },
  };
};
const mapCorporateDocument = (
  src: CorporateDocumentDataFragment,
): CorporateDocument => {
  return {
    id: src.Id,
    name: src.Name,
    employee: src.createdBy
      ? {
          id: src.createdBy.Id,
          name: src.createdBy.Name,
          avatarUrl: src.createdBy.avatar?.url ?? undefined,
        }
      : { id: DOCUMENT_OWNERS.HR, name: DOCUMENT_OWNERS.HR },
    addedOn: src.CreatedDate,
    type: getRowFileType(
      src.ContentDocumentLinks as ContentDocumentLinksDataFragment[],
    ),
    files: src.ContentDocumentLinks.map((x) => mapContentDocumentLinks(x)),
  };
};
const formatDocumentLocation = (path: string): string => {
  const pathArray = path.split('/');

  return [ROOT_FOLDER, ...pathArray].join(' / ');
};
export const mapPersonalDocumentPreview = (
  document: Navigatable & { file: File | undefined },
): DocumentPreview => {
  return {
    id: document.id,
    title: document.file ? document.file.title : '-',
    note: document.note,
    category: document.category,
    location: formatDocumentLocation(document.fullPath),
    employee: document.employee,
    type: document.file ? document.file.type : '-',
    typeName: document.file
      ? document.file.typeName
      : NavigatableType.EmployeeDocument,
    status: document.status,
    size: document.file ? document.file.size : 0,
    downloadLink: document.file ? document.file.publicLink?.downloadLink : '-',
    viewLink: document.file ? document.file.publicLink?.viewLink : '-',
    recordId: document.recordId,
    categoryId: document.categoryId,
  };
};

export const mapCorporateDocumentPreview = (
  document: CorporateDocument,
  file: File,
): DocumentPreview => {
  return {
    id: document.id,
    typeName: file.typeName,
    title: file.title,
    note: '',
    category: null,
    location: '',
    size: file.size,
    employee: document.employee,
    type: file.type,
    status: null,
    downloadLink: file.publicLink?.downloadLink,
    viewLink: file.publicLink?.viewLink,
    recordId: file.id,
    categoryId: null,
  };
};

export const mapPersonalDocumentFolder = (
  src: PersonalDocumentFolderDataFragment,
  currentEmployeeId: string,
): Navigatable => ({
  id: src.Id,
  name: src.Name,
  addedOn: src.CreatedDate,
  employee: src.createdBy
    ? mapCreatedByEmployee(src.createdBy, currentEmployeeId)
    : { id: DOCUMENT_OWNERS.HR, name: DOCUMENT_OWNERS.HR },
  type: { filesCount: 0, type: RowFileTypeEnums.Folder },
  category: i18next.t(`${i18Path}.category.folder`),
  status: 'APPROVED',
  typeName: NavigatableType.EmployeeDocumentFolder,
  fullPath: src.fullPath,
  note: null,
  recordId: null,
  commentsCount: 0,
  categoryId: null,
  // It's hard to implement canDelete validation for the folders in advance
  // So for folders it happens during delete action
  canDelete: true,
});
export const isMe = (
  currentEmployeeId: string,
  employeeId: string,
): boolean => {
  return currentEmployeeId === employeeId;
};
const i18Path = 'documents2.personal';
export const mapCreatedByEmployee = (
  src: DocumentCreatedByColleagueFragment,
  currentEmployeeId: string,
): DocumentEmployee => ({
  id: src.Id,
  name: isMe(currentEmployeeId, src.Id)
    ? i18next.t(`${i18Path}.table.addedBy.you`)
    : src.Name,
  avatarUrl: src.avatar?.url ?? undefined,
});
export const getDocumentItemStatusColor = (status: DocumentStatus): string =>
  Theme.documents.status[status].color;

export const defaultBreadcrumbState = (rootFolderId: string): Breadcrumb[] => {
  return [{ id: rootFolderId, name: i18next.t(`${i18Path}.table.home`) }];
};
