import { useState } from 'react';
import { useLogin } from '../../../../hooks/useLogin';
import { isLoginResultSuccess, loginByEmail } from '../../../../api/jwtAuth';

export const useJwtAuth = () => {
  const [loginErrors, setLoginErrors] = useState<boolean>(false);
  const login = useLogin();

  const doLogin = async (email: string, password: string) => {
    const loginResult = await loginByEmail(email, password);
    if (isLoginResultSuccess(loginResult)) {
      login(`Bearer ${loginResult.accessToken}`);
      return 'success';
    } else {
      setLoginErrors(true);
    }
  };

  return { doLogin, loginErrors };
};
