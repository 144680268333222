import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Form } from '../../../components/form/Form';
import SubmitButton from '../../../components/form/SubmitButton';
import { routes } from '../routes';

// Session Inactivity Timeout
const SessionTimeout: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleRelogin = () => {
    history.push(routes.login.route.create({}));
  };

  return (
    <>
      <h1 className="display-4 text-center mb-3">
        {t('sessionTimeout.title')}
      </h1>
      <p className="text-muted text-center mb-5">
        {t('sessionTimeout.message')}
      </p>
      <Form onSubmit={handleRelogin}>
        <SubmitButton block>{t('sessionTimeout.reloginButton')}</SubmitButton>
      </Form>
    </>
  );
};

export default SessionTimeout;
