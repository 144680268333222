import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import 'moment-timezone';

import './initializers/yup';
import './initializers/i18next';
import './initializers/moment';

import App from './App';
import { ToastProvider } from 'react-toast-notifications';

import './initializers/flatpickr';
import './vendor/dashkit/js/chart';
import './vendor/dashkit/css/theme.bundle.css';
import './vendor/dashkit/css/libs.bundle.css';
import './vendor/dashkit/css/override.css';

import { ToastContainer } from './components/toast/ToastContainer';

import { RollbarProvider } from './apps/dashboard/context/Rollbar';

// Here is a fix to `unsafe-eval` problem caused by CSP
// from here https://stephencharlesweiss.com/unsafe-eval-regenerator-runtime
(globalThis as any).regeneratorRuntime = undefined;

function registerServiceWorker() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/sw.js', { scope: '/' })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    });
  }
}

registerServiceWorker();

ReactDOM.render(
  <RollbarProvider>
    <ToastProvider components={{ ToastContainer: ToastContainer }}>
      <App />
    </ToastProvider>
  </RollbarProvider>,
  document.getElementById('root'),
);
