import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLogin } from '../../../../hooks/useLogin';
import { useMutationErrorHandler } from '../../../../hooks/useMutationErrorHandler';
import { LoginMutation, useLoginMutation } from '../../__generated__/graphql';
import { FetchResult } from '@apollo/client';
import LoginPasswordFormCommon, {
  AlertMessage,
  LoginFormData,
} from './LoginPasswordFormCommon';

const LoginPasswordForm: React.FC = () => {
  const [loginErrors, setLoginErrors] = useState<boolean>(false);
  const login = useLogin();
  const [doLogin] = useLoginMutation();
  const errorHandler = useMutationErrorHandler();
  const { t } = useTranslation();

  const handleLogin = (result: FetchResult<LoginMutation>) => {
    if (result.errors || !result?.data?.login) {
      setLoginErrors(true);
      return;
    }

    login(result.data.login);
  };

  const onSubmit = async (values: LoginFormData) => {
    setLoginErrors(false);
    await doLogin({ variables: values })
      .then((result) => handleLogin(result))
      .catch(errorHandler);
  };

  return (
    <>
      <h1 className="display-4 text-center mb-3">{t('signIn.title')}</h1>
      <p className="text-muted text-center mb-5">{t('signIn.subTitle')}</p>
      <AlertMessage />
      <LoginPasswordFormCommon loginErrors={loginErrors} onSubmit={onSubmit} />
    </>
  );
};

export default LoginPasswordForm;
