import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import React from 'react';
import { getApiBaseUrl } from '../../utils/config';

const devMode = import.meta.env.MODE !== 'production';

const client = new ApolloClient({
  name: 'web',
  version: import.meta.env.VITE_APP_CODE_VERSION,
  uri: getApiBaseUrl() + '/public/graphql',
  cache: new InMemoryCache(),
  headers: devMode
    ? {
        // we need it to suport domain slug for local env, ex: http://platform-ability-2883-dev-ed.localhost:3001/login
        'x-forwarded-host': window.location.host,
      }
    : undefined,
});

export const Provider: React.FC = ({ children }) => (
  <ApolloProvider client={client} children={children} />
);
